import React from 'react'

function Card({title, about, icon, image}) {
    
   
  return (
    <div className='serviceCard relative bg-[#0C0C0C] overflow-visible border border-solid border-[#383838] h-[21rem] w-[30%] md:max-w-[18rem] rounded-[0.625rem] z-50 cardBG px-8 py-8 parent '>
        
        <div className='gifdiv'></div>

        {/* <Lottie animationData={staticBg} className='opacity-[5%] absolute w-[49rem] h-[21rem] top-0 left-0 overflow-hidden serviceCardLottie'/> */}
        {/* Image */}
        <div className='absolute -top-[11%] right-[-10%] image opacity-0 duration-100 overflow-visible '>
            <img alt='' src={image} className='rounded-lg overflow-visible' width='70%'/>
        </div>

        {/* Icon */}
        <div className='absolute right-5 icon duration-200 '>
            <img alt='' src={icon} width='80%'/>
        </div>
        
        {/* Text */}
        <p className='text-[#fff] my-[1rem] md:mt-[6rem] md:w-[10rem] leading-8  text-[1.5rem] skConcretica'>{title}</p>
        <p className='text-[#ffffff99] text-[1rem] hidden md:flex mt-5 dmSans'>{about}</p>
    </div>
  )
}

export default Card
import React from 'react'
import logo1 from '../Assets/Logos/logo1.png'
import logo2 from '../Assets/Logos/logo2.png'
import logo3 from '../Assets/Logos/logo3.png'
import logo4 from '../Assets/Logos/logo4.png'
import logo5 from '../Assets/Logos/logo5.png'
import logo6 from '../Assets/Logos/logo6.png'
import logo7 from '../Assets/Logos/logo7.png'
import logo8 from '../Assets/Logos/logo8.png'
import logo9 from '../Assets/Logos/logo9.png'
import logo10 from '../Assets/Logos/logo10.png'
import logo11 from '../Assets/Logos/logo11.png'
import logo12 from '../Assets/Logos/logo12.png'
import logo13 from '../Assets/Logos/logo13.png'
import logo14 from '../Assets/Logos/logo14.png'
import logo15 from '../Assets/Logos/logo15.png'
import logo16 from '../Assets/Logos/logo16.png'
import logo17 from '../Assets/Logos/logo17.png'
import logo18 from '../Assets/Logos/logo18.png'
import logo19 from '../Assets/Logos/logo19.png'
import logo20 from '../Assets/Logos/logo20.png'
import logo21 from '../Assets/Logos/logo21.png'
import purpleBg from '../Assets/clientBg.png'
import { Fade } from 'react-reveal'

const Clients = () => { 
  return (
    <div className='clients pt-[9rem] w-[100%] mx-auto'>

        <div className='clientsBg'></div>

        <Fade top distance='20%' duration='1500'>
            <h2 className='clientsHeading text-center overlay-div clashDisplay text-[#fff] text-[3.3rem] mb-[4rem]'>
                Our Esteemed 
                <span className='skConcretica'> Clientele</span>
            </h2>
        </Fade>

        {/* Sliding Logos row 1*/}
        <div className='logos mb-[5rem] overlay flex gap-[109rem]'>

            <div className='logo-slide flex gap-[5rem]'>               
                <img alt='' src={logo1}/>
                <img alt='' src={logo2}/>
                <img alt='' src={logo3}/>
                <img alt='' src={logo4}/>
                <img alt='' src={logo5}/>
                <img alt='' src={logo6}/>
                <img alt='' src={logo7}/>
                <img alt='' src={logo1}/>
                <img alt='' src={logo2}/>
                <img alt='' src={logo3}/>
                <img alt='' src={logo4}/>
                <img alt='' src={logo5}/>
                <img alt='' src={logo6}/>
                <img alt='' src={logo7}/>                
            </div>
            <div className='logo-slide flex gap-[5rem]'>               
                <img alt='' src={logo1}/>
                <img alt='' src={logo2}/>
                <img alt='' src={logo3}/>
                <img alt='' src={logo4}/>
                <img alt='' src={logo5}/>
                <img alt='' src={logo6}/>
                <img alt='' src={logo7}/>    
                <img alt='' src={logo1}/>
                <img alt='' src={logo2}/>
                <img alt='' src={logo3}/>
                <img alt='' src={logo4}/>
                <img alt='' src={logo5}/>
                <img alt='' src={logo6}/>
                <img alt='' src={logo7}/>           
            </div>   

        </div>

        {/* Sliding Logos row 2*/}
        <div className='logosRight mb-[5rem] overlay flex gap-[109rem]'>

            <div className='logo-slideRight flex gap-[5rem]'>               
                <img alt=''  src={logo8}/>
                <img alt='' src={logo9}/>
                <img alt='' src={logo10}/>
                <img alt='' src={logo11}/>
                <img alt='' src={logo12}/>
                <img alt='' src={logo13}/>
                <img alt='' src={logo14}/>   
                <img alt='' src={logo8}/>
                <img alt='' src={logo9}/>
                <img alt='' src={logo10}/>
                <img alt='' src={logo11}/>
                <img alt='' src={logo12}/>
                <img alt='' src={logo13}/>
                <img alt='' src={logo14}/>           
            </div>
            <div className='logo-slideRight flex gap-[5rem]'>               
                <img alt='' src={logo8}/>
                <img alt='' src={logo9}/>
                <img alt='' src={logo10}/>
                <img alt='' src={logo11}/>
                <img alt='' src={logo12}/>
                <img alt='' src={logo13}/>
                <img alt='' src={logo14}/> 
                <img alt='' src={logo8}/>
                <img alt='' src={logo9}/>
                <img alt='' src={logo10}/>
                <img alt='' src={logo11}/>
                <img alt='' src={logo12}/>
                <img alt='' src={logo13}/>
                <img alt='' src={logo14}/>              
            </div>

        </div>

        {/* Sliding Logos row 3*/}
        <div className='logos mb-[5rem] overlay flex gap-[109rem]'>

            <div className='logo-slide flex gap-[5rem]'>               
                <img alt='' src={logo15}/>
                <img alt='' src={logo16}/>
                <img alt='' src={logo17}/>
                <img alt='' src={logo18}/>
                <img alt='' src={logo19}/>
                <img alt='' src={logo20}/>
                <img alt='' src={logo21}/>
                <img alt='' src={logo15}/>
                <img alt='' src={logo16}/>
                <img alt='' src={logo17}/>
                <img alt='' src={logo18}/>
                <img alt='' src={logo19}/>
                <img alt='' src={logo20}/>
                <img alt='' src={logo21}/>         
            </div>
            <div className='logo-slide flex gap-[5rem]'>               
                <img alt='' src={logo15}/>
                <img alt='' src={logo16}/>
                <img alt='' src={logo17}/>
                <img alt='' src={logo18}/>
                <img alt='' src={logo19}/>
                <img alt='' src={logo20}/>
                <img alt='' src={logo21}/>
                <img alt='' src={logo15}/>
                <img alt='' src={logo16}/>
                <img alt='' src={logo17}/>
                <img alt='' src={logo18}/>
                <img alt='' src={logo19}/>
                <img alt='' src={logo20}/>
                <img alt='' src={logo21}/>            
            </div>
            
        </div>

        <div className='image-div'>
            <img alt='' src={purpleBg}/>
        </div>

    </div>
  )
}

export default Clients
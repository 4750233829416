import React from 'react'
import kevin from '../Assets/Profile/kevin.png'
import gagandhar from '../Assets/Profile/gagandhar.png'
import shivansh from '../Assets/Profile/shivansh.png'
import kanani from '../Assets/Profile/kanani.png'
import priyanka from '../Assets/Profile/priyanka.png'
import deep from '../Assets/Profile/deep.png'
import Testimonial from './Testimonial'
import { Fade } from 'react-reveal'

const AdoredByClients = () => {

    const testimonialData =[
        {
            id: 1,
            name: 'Kevin Shah',
            review: 'Excellent work or rather exceptional work with lot of creativity and clarity in concept.Amazing staff and helpful young enthusiasts.Delivering all the artworks before deadline and changes as per our need.Great working with Jinay and would recommend to many more !',
            profile: kevin
        },
        {
            id: 2,
            name: 'Gangadhar Reddy',
            review: 'This  is the best team so far i have seen for Graphic designing . People here gives you utmost care to client requirements and respect to your thoughts',
            profile: gagandhar
        },
        {
            id: 3,
            name: 'Shivansh Packaging',
            review: "We, at *Shivansh Packaging Machines*, duly appreciate the efforts put in from Team 'Trebled'. I want to take the time to thank you for the extraordinary job you have done for our company's image buildings & logo Animation. ",
            profile: shivansh
        },
        {
            id: 4,
            name: 'Kanani Vandana',
            review: 'Trebled has listened to our needs, is quite simply a great designer. They have a brilliant eye for what looks good, and quickly understands a brief and the brand behind the brief and we have been pleased with the creative design concepts.  Highly recommended.”',
            profile: kanani
        },
        {
            id: 5,
            name: 'Priyanka Salvi',
            review: '“Had great experience working with trebled. They have actually designed what we have visualised and brought innovation in it. Patiently worked and incorporated our changes. Thanks to the team for making eye catching designs.”',
            profile: priyanka
        },
        {
            id: 6,
            name: 'Deep Hindocha',
            review: 'A one stop solution for businesses.Professional client relations and management.Reception by a set of skillful minds',
            profile: deep
        },
        
    ]

  return (
    <div className='mt-[5rem] adoredMain p-10 flex flex-col gap-[3rem] overflow-x-hidden'>

        <Fade top distance='30%' duration='2000'>
        <div>
            <h2 className='text-[#fff] text-[3.2rem] text-center clashDisplay'>Adored by<span className='skConcretica'> Clients</span></h2>
        </div>
        </Fade>

        <div className=' testimonials flex gap-[2.5rem] '>
            <div className='flex gap-[2.5rem] childTestimonial'>
                {
                    testimonialData.map((testimonial) =>(
                        <Testimonial testimonial={testimonial} key={testimonial.id}/>
                    ))
                }
            </div>
            <div className='flex gap-[2.5rem] childTestimonial'>
                {
                    testimonialData.map((testimonial) =>(
                        <Testimonial testimonial={testimonial} key={testimonial.id}/>
                    ))
                }
            </div>
            
        </div>
    </div>
  )
}

export default AdoredByClients
import React from 'react'

const FooterBase = () => {
  return (
    <div className='flex text-[#fff] dmSans w-[80%] mx-auto footerBase justify-between'>
        <p>©2023 Trebled, All Rights Reserved.</p>
        <p className='flex footerPP'>Privacy Policy</p>
    </div>
  )
}

export default FooterBase
import React from 'react'
import { NavLink } from 'react-router-dom';

function Slide({image, buttons, page}) {

  function handleClick(button){
    console.log(button);
  }

  // console.log('prinint page')
  // console.log(page);

  const navigateHandler = () =>{
    window.scrollTo({top:-50,left:0});
  }
    
  return (
    <div className='relative slideCard w-[22rem]'>
      <NavLink to={page} onClick={navigateHandler}>
        <img src={image} width='' className='z-10' alt=''/>
      </NavLink>

        <div className='text-[#fff] clashDisplay absolute top-[1rem] right-[1.5rem] z-20 flex gap-2'> 
          {
            buttons.map((button,index)=>(
              <p key={index} onClick={()=>handleClick(button)} className='border-[2px] border-solid border-[#ffffff1a] rounded-xl p-1 px-2 flex uppercase cursor-pointer'>{button}</p>
            ))
          }
        </div>
    </div>
  )
}

export default Slide
import React from 'react'
import oji from '../Assets/OJI.svg'
import foodAdda from '../Assets/foodAdda.png'
import alpha from '../Assets/aplha.png'
import purpleBG from '../Assets/visible work bg.png'
import { Fade } from 'react-reveal'

const VisibleWork = () => {
   
  return (
    <div id='visibleWork' className='visbleMain mx-auto flex relative mt-[4rem] flex-row  justify-evenly'>

        {/* Left Part */}
        <Fade top distance='20%' duration='1500'>
            <div className='leftContent relative'>
                <div className='w-[35rem] visiblePara p-[3.8rem] pl-[6rem] mt-[3rem]'>
                    <h2 className='clashDisplay visibleWorkHeading text-[#fff] text-[3.2rem] mb-[3rem] leading-[3.8rem] z-50'>Work that is <span className='skConcretica'> visible !!</span></h2>
                    <p className='visibleWorkPara text-[#fff] dmSans text-[1.2rem] leading-[2rem] z-50'>
                    Explore a showcase of our latest accomplishments and projects, highlighting our expertise, creativity, and commitment to excellence
                    </p>
                </div>
                <div className='absolute -left-[10%] -top-[40%] w-[37rem] visibleImage z-0'>
                    <img alt=''  src={purpleBG} height='10rem'/>
                </div>
            </div>
        </Fade>

        {/* Right Part */}
        <div className='visibleWork'>

            {/* First Div */}
            <div className='text-[#fff] flex flex-col card w-[48rem] '>

                {/* Div Header */}
                <div className='flex justify-between px-[2rem] items-center'>

                    <div className='flex text-[2.5rem]'>
                        <p className='skConcretica pl-2 pr-[2rem] py-[1.5rem] visibleHeadNo'>01</p>
                        <p className='clashDisplay visibleHeadName font-semibold py-[1.5rem] pl-[3rem] border-l-[1px] h-full border-l-[#383838]'>OJI</p>
                    </div>

                    <div className='flex gap-5'>
                    {/* eslint-disable-next-line */}
                        <a href='http://www.ojihub.com' target='_blank'><p className='clashDisplay text-[1.1rem] font-medium visibleLink'>www.ojihub.com</p></a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 36 36" fill="none" className='visibleArrow'>
                            <path d="M9.8076 11.0873L9.8076 8L25.5253 8C26.3398 8 27 8.69119 27 9.54367L27 25.9966L24.0506 25.9966L24.0506 13.3818L10.0856 28L8 25.8169L22.0715 11.0873L9.8076 11.0873Z" fill="white"/>
                        </svg>
                    </div>

                </div>

                {/* Div Main content */}
                <div className=' border-t-[1px] border-t-[#383838] py-[3rem] '>
                    
                    <div className='flex cardContent w-[90%] gap-12 mx-auto'>
                        <div className='ojiBg'>
                            <img alt=''  src={oji} width='60%'/>
                        </div>

                        <div className='flex flex-col gap-6'>
                            <h3 className='clashDisplay cardHeading text-[1.75rem] w-full'>Oji (On Jhakas Internet):<span className='skConcretica'>  India's <br/> trailblazing WiFi brand.</span> </h3>

                            <p className='dmSans cardPara text-[0.9rem] text-[#ffffff99] leading-[1.5rem]'>We successfully built OJI's brand identity and launched a powerful social media campaign, establishing a strong online presence and achieving brand goals. Our approach enhanced OJI's prominence in the PM-WANI PDOA space, with the website as a central hub, improving user accessibility.<br/><br/> 
                                <span>Our strategic campaign effectively conveyed OJI's mission to transform the digital landscape and bridge the digital divide, increasing brand awareness and attracting a broader audience</span>
                            </p>

                            <div className='flex flex-wrap uppercase dmSans text-[0.7rem] font-bold gap-4 tagDiv'>
                                <p className='tag'>Logo</p>
                                <p className='tag'>branding</p>
                                <p className='tag'>website</p>
                                <p className='tag'>mobile app</p>
                                <p className='tag'>social media marketing</p>
                            </div>

                        </div>
                    </div>

                </div>
                
            </div>

            {/* Second Div */}
            <div className='text-[#fff] flex flex-col card w-[48rem]'>

                {/* Div Header */}
                <div className='flex justify-between px-[2rem] items-center'>

                    <div className='flex text-[2.5rem] '>
                        <p className='skConcretica pl-2 pr-[2rem] py-[1.5rem] visibleHeadNo'>02</p>
                        <p className='clashDisplay visibleHeadName font-semibold py-[1.5rem] pl-[3rem] border-l-[1px] h-full border-l-[#383838]'>FoodAdda</p>
                    </div>

                    <div className='flex gap-5'>
                    {/* eslint-disable-next-line */}
                        <a href='http://www.foodaddaindia.com' target='_blank'><p className='clashDisplay text-[1.1rem] font-medium visibleLink'>www.foodaddaindia.com</p></a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 36 36" fill="none" className='visibleArrow'>
                            <path d="M9.8076 11.0873L9.8076 8L25.5253 8C26.3398 8 27 8.69119 27 9.54367L27 25.9966L24.0506 25.9966L24.0506 13.3818L10.0856 28L8 25.8169L22.0715 11.0873L9.8076 11.0873Z" fill="white"/>
                        </svg>
                    </div>

                </div>

                {/* Div Main content */}
                <div className='border-t-[1px] border-t-[#383838] py-[3rem] '>
                    
                    <div className='flex w-[90%] gap-12 mx-auto cardContent'>
                        <div className='foodAddaBg'>
                            <img alt=''  src={foodAdda} width='80%'/>
                        </div>

                        <div className='flex flex-col gap-6'>
                            <h3 className='clashDisplay text-[1.75rem] w-full'>Foodadda :<span className='skConcretica'>  A pure vegetarian luxury<br/> fast food chain in India</span> </h3>

                            <p className='dmSans cardPara text-[0.9rem] text-[#ffffff99] leading-[1.5rem]'>
                                Our focus during the website development was to ensure that it reflects FoodAdda's commitment to excellence. We integrated features that highlight their core values: High Quality, Safety & Hygiene, Affordable Prices, and an Unforgettable Taste.<br/> <br/>
                                <span>FoodAdda's success story is built on these pillars, and we've strived to create a digital platform that mirrors their dedication to providing a memorable dining experience.</span>
                            </p>

                            <div className='flex flex-wrap uppercase dmSans text-[0.7rem] font-bold gap-4 tagDiv'>
                                <p className='tag'>website</p>
                                <p className='tag'>social media marketing</p>
                            </div>

                        </div>
                    </div>

                </div>
                

            </div>

            {/* Third Div */}
            <div className='text-[#fff] flex flex-col card w-[48rem]'>

                {/* Div Header */}
                <div className='flex justify-between px-[2rem] items-center'>

                    <div className='flex text-[2.5rem] '>
                        <p className='skConcretica pl-2 pr-[2rem] py-[1.5rem] visibleHeadNo'>03</p>
                        <p className='clashDisplay visibleHeadName font-semibold py-[1.5rem] pl-[3rem] border-l-[1px] h-full border-l-[#383838]'>Alpha Capital</p>
                    </div>

                    <div className='flex gap-5'>
                    {/* eslint-disable-next-line */}
                        <a href='http://alphacapitalgroup.uk/' target='_blank'><p className='clashDisplay text-[1.1rem] font-medium visibleLink'>www.alphacapitalgroup.uk</p></a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 36 36" fill="none" className='visibleArrow'>
                            <path d="M9.8076 11.0873L9.8076 8L25.5253 8C26.3398 8 27 8.69119 27 9.54367L27 25.9966L24.0506 25.9966L24.0506 13.3818L10.0856 28L8 25.8169L22.0715 11.0873L9.8076 11.0873Z" fill="white"/>
                        </svg>
                    </div>

                </div>

                {/* Div Main content */}
                <div className='border-t-[1px] border-t-[#383838] py-[3rem] '>
                    
                    <div className='flex w-[90%] gap-12 mx-auto cardContent'>
                        <div className='alphaBg'>
                            <img alt=''  src={alpha} width='80%'/>
                        </div>

                        <div className='flex flex-col gap-6'>
                            <h3 className='clashDisplay text-[1.75rem] w-full'>Alpha Capital :<span className='skConcretica'>  Empowering Traders<br/> with Elite Financial Expertise</span> </h3>

                            <p className='dmSans cardPara text-[0.9rem] text-[#ffffff99] leading-[1.5rem]'>
                                We designed a website for Alpha Capital Group, a company that offers funding up to £2,000,000 for profitable traders who can demonstrate an ability to react to the markets, manage risk and most importantly generate profits. <span>Our goal was to create a website that showcases their unique and innovative business model, their value proposition, and their success stories.<br/> <br/>
                                We used a responsive design that adapts to different screen sizes and devices, ensuring that the website looks great and functions well on any platform.</span>
                            </p>

                            <div className='flex flex-wrap uppercase dmSans text-[0.7rem] font-bold gap-4 tagDiv'>
                                <p className='tag'>website</p>
                                <p className='tag'>social media marketing</p>
                            </div>

                        </div>
                    </div>

                </div>
                

            </div>
   
        </div>

        

    </div>
  )
}

export default VisibleWork
import React from 'react'
import frame1 from '../Assets/Frame1.png'
import frame2 from '../Assets/Frame2.png'
import frame3 from '../Assets/Frame3.png'
import frame4 from '../Assets/Frame4.png'
import frame5 from '../Assets/Frame5.png'
import frame6 from '../Assets/Frame6.png'
import frame7 from '../Assets/Frame7.png'
import frame8 from '../Assets/Frame8.png'
import frame9 from '../Assets/Frame9.png'
import frame10 from '../Assets/Frame10.png'
import frame11 from '../Assets/Frame11.png'
import frame12 from '../Assets/Frame12.png'
import Slide from './Slide'
import blur from '../Assets/snippetBlur.png'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'

function Slides() {

    const slideData=[
        {
            image: frame1,
            buttons: ['logo'],
            page: "/work/truffles"
        },
        {
            image: frame2,
            buttons: ['logo'],
            page: "/work/report_prime"
        },
        {
            image: frame3,
            buttons: ['logo','website'],
            page: "/work/envy"
        },
        {
            image: frame4,
            buttons: ['logo','website'],
            page: "/work/lynxcx"
        },
        {
            image: frame5,
            buttons: ['web app design'],
            page: "/work"
        },
        {
            image: frame6,
            buttons: ['branding','ui/ux','smm'],
            page: "/work/oji"
        },
        {
            image: frame7,
            buttons: ['website'],
            page: "/work/foodadda"
        },
        {
            image: frame8,
            buttons: ['logo'],
            page: "/work/oji"
        },
        {
            image: frame9,
            buttons: ['logo'],
            page: "/work"
        },
        {
            image: frame10,
            buttons: ['website','ui/ux'],
            page: "/work/alpha_capital"
        },
        {
            image: frame11,
            buttons: ['logo'],
            page: "/work"
        },
        {
            image: frame12,
            buttons: ['logo'],
            page: "/work/fantazy"
        },
    ];

    const navigate = useNavigate();

    const workNavigateHandler = () =>{
        navigate('/work');
        window.scrollTo({top:0,left:0});
    }

  return (
    <div id='work' className='slidesMain'>

        <Fade top distance='20%' duration='1500'>
            <p className='skConcretica text-[#fff] my-[5rem] snippetHeadMob' id='work'><span className='clashDisplay'>Snippet of</span> our work!</p>
        </Fade>

        <div className='flex  slideCards gap-0'>
            <div className='flex z-20 slideCardFirst deskCard'>
                {
                    slideData.map((slide,index)=>{
                        return <Slide image={slide.image} buttons={slide.buttons} page={slide.page} key={index} />
                    })
                }
            </div>
            <div className='slideCardSecond z-20 flex deskCard'>
                {
                    slideData.map((slide,index)=>{
                        return <Slide image={slide.image} buttons={slide.buttons} key={index} />
                    })
                }
            </div>
        </div>

        <div className='w-[80%] mx-auto flex justify-between items-center relative z-0'>
            
            <Fade top distance='20%' duration='1500'>
            <p className='skConcretica text-[#fff] text-[3.7rem] my-[5rem] snippetHead'><span className='clashDisplay'>Snippet of</span> our work!</p>
            </Fade>
            
            <button onClick={workNavigateHandler} className='text-[#fff] border-solid border-[1px] border-[#fff] bg-[#6343FF] slidesButton  rounded-[0.7rem] p-3 px-12'>View All</button>
            
            <div className='absolute -right-[36rem] -top-[20rem] -z-10'>
                <img src={blur} width='60%' className='-z-[2]' alt=''/>
            </div>
            
        </div>

    </div>
    
  )
}

export default Slides
import React, { useState } from 'react'
import icon1 from '../Assets/rocketIcon.svg'
import icon2 from '../Assets/dollarIcon.svg'
import icon3 from '../Assets/magnetIcon.svg'
import workEllipse from '../Assets/workEllipse.png'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { Fade } from 'react-reveal'


function Work() {

    const [counterState, setCounterState] = useState(false);

    const projData = [
        {
            name: 'Food',
        },
        {
            name: 'fintech',
        },
        {
            name: 'ecom',
        },
        {
            name: 'edtech',
        },
        {
            name: 'crypto',
        },
    ]

    return (
        <div className='bg-[#101010] workMain rounded-[1rem] mx-[1rem] pt-[2rem]' id='aboutus'>

            {/* About Section */}
            <div className='bg-[#0D0C0D] workAboutSection border-[1px] border-[#383838] rounded-[2rem] flex gap-5 mx-6 h-[50rem] pt-[5rem] p-5 relative'>

                {/* Button */}
                <div className='ml-[5rem] workButton z-10'>
                    <button className=' text-[#fff] border-solid flex border-[1px] border-[#fff] bg-[#6343FF] dmSans text-[0.98rem]  rounded-[0.7rem] p-[0.9rem] px-[2.8rem]'>At Trebled</button>
                </div>

                {/* Background Vector */}
                <div className='absolute right-0 workBG -top-[5.7rem] flex z-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1174" height="742" viewBox="0 0 1564 742" fill="none">
                        <path opacity="0.22" d="M1.00317 0V209.5L1.00171 463.5C1.00171 496.635 30.3554 523.5 66.5636 523.5H105.901C142.109 523.5 171.463 550.365 171.463 583.5L171.466 681C171.466 714.135 200.82 741 237.028 741H1564" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-dasharray="4 3" />
                    </svg>
                </div>

                <div className='w-[60%] ml-[5rem] mx-auto workDiv'>
                    {/* Heading */}
                    <Fade top distance='20%' duration='1500'>
                        <h2 className='text-[#fff] w-[40rem] clashDisplay -mt-[1rem] workHeading leading-[4rem] text-[3.25rem]'>
                            We're not just designers, <span className='skConcretica'>we're strategic partners.</span>
                        </h2>
                    </Fade>

                    {/* Para */}
                    <Fade top distance='20%' duration='1500'>
                        <p className='text-[#fff] workPara openSans text-[1.1rem] leading-[2.5rem] ml-[4rem] mt-[3.5rem]'> From ideation to implementation, we take the initiative and work proactively to help our clients succeed. <span className='workSpan'>Our team of experts crafts solutions that are not only beautiful, but also effective, user-friendly, and aligned with your business goals. Partner with us to bring your vision to life and thrive in the digital realm.</span></p>
                    </Fade>

                    {/* Cards */}
                    <Fade top distance='20%' duration='1500'>
                        <div className='flex workCards items-center gap-10 z-50 mt-[3rem] flex-row ml-[4rem]'>

                            <div className='bg-[#6343FF] workCard workCard1 flex-grow z-50 h-[12rem] w-[11.5rem] flex flex-col items-center pt-6 p-4 py-5 rounded-2xl gap-4'>
                                <img alt='' src={icon1} width='' className='workCardIcon' />
                                <p className='openSans miniCard text-[#fff] text-[1.1rem] leading-[1.3rem]  mt-3 text-center'>Elevating Brands </p>
                            </div>

                            <div className='bg-[#FF3E99] workCard workCard2 z-50 flex-grow h-[12rem] w-[11.5rem] flex flex-col items-center pt-6 p-4 py-5 rounded-2xl gap-4'>
                                <img alt='' src={icon2} width='' className='workCardIcon' />
                                <p className='openSans miniCard text-[#fff] text-[1.1rem] leading-[1.3rem]  text-center'>Driving Conversion</p>
                            </div>

                            <div className='bg-[#F0B500] workCard workCard3 z-50 flex-grow h-[12rem] w-[11.5rem] flex flex-col items-center pt-6 p-4 py-5 rounded-2xl gap-4'>
                                <img alt='' src={icon3} width='' className='workCardIcon' />
                                <p className='openSans miniCard text-[#fff] text-[1.1rem] leading-[1.3rem] mt-2  text-center'>Fostering Engagement</p>
                            </div>

                        </div>
                    </Fade>

                </div>

                {/* Purple BG */}
                <div className='absolute -left-[8rem] workBG'>
                    <img alt='' src={workEllipse} />
                </div>

            </div>

            {/* No of projects section */}
            <Fade top distance='20%'>
                <div className='w-full workProj flex flex-col items-center pt-[5rem] relative dottedBG'>

                    <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                        <div className='text-[#fff] text-center'>
                            <h2 className='projecth2 clashDisplay font-[500] text-[6rem]'>
                                {
                                    counterState &&
                                    <CountUp start={600} end={888} duration={5}></CountUp>
                                }+
                            </h2>
                            <h3 className='projecth3 skConcretica text-[4rem] -mt-[2rem] mb-[2rem]'>Projects Delivered</h3>
                        </div>
                    </ScrollTrigger>


                    <div className='projectCardDiv text-[#fff] flex flex-wrap justify-center gap-3 mb-[5rem]'>
                        {
                            projData.map((data, index) => {
                                return (
                                    <p key={index} className='projectCard openSans text-[0.7rem] font-[600] border-[#ffffff1a] border-[2px] rounded-lg py-2 px-4 uppercase'>{data.name}</p>
                                )
                            })
                        }
                    </div>



                </div>
            </Fade>
        </div>
    )
}

export default Work
import Navbar from './Components/Navbar';
import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './Routes/HomePage';
import WorkRoute from './Routes/WorkRoute';
import ContactUs from './Routes/ContactUs';
import Truffles from './Routes/WorkPages/Truffles';
import Foodadda from './Routes/WorkPages/Foodadda';
import Oji from './Routes/WorkPages/Oji';
import Envy from './Routes/WorkPages/Envy';
import Intellexo from './Routes/WorkPages/Intellexo';
import Botego from './Routes/WorkPages/Botego';
import Modernite from './Routes/WorkPages/Moderite';
import ReportPrime from './Routes/WorkPages/ReportPrime';
import ZeroAssetGroup from './Routes/WorkPages/ZeroAssetGroup';
import AlphaCapital from './Routes/WorkPages/AlphaCapital';
import Flivery from './Routes/WorkPages/Flivery';
import AanthroBotAi from './Routes/WorkPages/AanthroBotAi';
import FootBalance from './Routes/WorkPages/FootBalance';
import Fantazy from './Routes/WorkPages/Fantazy';
import LynxCx from './Routes/WorkPages/LynxCx';

function App() {
  return (
    <div className="bg-[#0C0C0C] mobileView">
      <Navbar/>

      <Routes>
        <Route index element={<HomePage/>}/>
        <Route path='/home' element={<HomePage/>}/>
        <Route path='/work' element={<WorkRoute/>}/>
        <Route path='/contactus' element={<ContactUs/>}/>
        <Route path='/work/truffles' element={<Truffles/>}/>
        <Route path='/work/foodadda' element={<Foodadda/>}/>
        <Route path='/work/oji' element={<Oji/>}/>
        <Route path='/work/envy' element={<Envy/>}/>
        <Route path='/work/intellexo' element={<Intellexo/>}/>
        <Route path='/work/botego' element={<Botego/>}/>
        <Route path='/work/modernite' element={<Modernite/>}/>
        <Route path='/work/report_prime' element={<ReportPrime/>}/>
        <Route path='/work/zero_asset_group' element={<ZeroAssetGroup/>}/>
        <Route path='/work/alpha_capital' element={<AlphaCapital/>}/>
        <Route path='/work/flivery' element={<Flivery/>}/>
        <Route path='/work/aanthrobot_ai' element={<AanthroBotAi/>}/>
        <Route path='/work/footbalance' element={<FootBalance/>}/>
        <Route path='/work/fantazy' element={<Fantazy/>}/>
        <Route path='/work/lynxcx' element={<LynxCx/>}/>
        <Route path='/work/*' element={<Navigate to='/work' />} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>

    </div>
  );
}

export default App;

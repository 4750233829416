import React from 'react'
import ellipseExplore from '../Assets/work/truffles/Ellipse 38.png'
import carousel1 from '../Assets/carousals/carousal1.png'
import carousel2 from '../Assets/carousals/carousal2.png'
import carousel3 from '../Assets/carousals/carousal3.png'
import carousel4 from '../Assets/carousals/carousal4.png'
import carousel5 from '../Assets/carousals/carousal5.png'
import carousel6 from '../Assets/carousals/carousal6.png'
import carousel7 from '../Assets/carousals/carousal7.png'
import carousel8 from '../Assets/carousals/carousal8.png'
import carousel9 from '../Assets/carousals/carousal9.png'
import { useNavigate } from 'react-router-dom'

const Carousel = () => {

    const Navigate = useNavigate();

    const carouselData =[
        {
            image: carousel1,
            route: '/work/truffles'
        },
        {
            image: carousel2,
            route: '/work/foodadda'
        },
        {
            image: carousel3,
            route: '/work/oji'
        },
        {
            image: carousel4,
            route: '/work/envy'
        },
        {
            image: carousel5,
            route: '/work/intellexo'
        },
        {
            image: carousel6,
            route: '/work/botego'
        },
        {
            image: carousel7,
            route: '/work/modernite'
        },
        {
            image: carousel8,
            route: '/work/report-prime'
        },
        {
            image: carousel9,
            route: '/work/zero-asset-group'
        }
    ];

    const workNavigateHandler = (carousal) =>{
        Navigate(carousal.route)
        window.scrollTo({top:0,left:0});
    }

  return (
    <div className='overflow-x-hidden'>

        {/* Carousel Heading*/}
        <div className='relative'>
            <div className='carousalHeader w-[80%] h-[20rem] mx-auto flex  items-center justify-center gap-[4rem]'>

                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="13" viewBox="0 0 87 18" fill="none">
                    <path d="M86.5 17L2 17L18 0.999994" stroke="white"/>
                </svg>
                <h4 className='text-[#fff] clashDisplay text-[2rem] carousalHeading'>Explore More?</h4>
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="13" viewBox="0 0 86 18" fill="none">
                    <path d="M0 1H84.5L68.5 17" stroke="white"/>
                </svg>

            </div>

            <div className='absolute -top-[17rem] right-0 w-[35rem] carousalEllipse'>
                <img alt='' src={ellipseExplore}/>
            </div>

        </div>

        {/* Carousel Main*/}
        <div className='mb-[10rem]'>
            <div className='flex w-[90%] mx-auto gap-[2rem] carousal'>
                {
                    carouselData.map((carousel,index)=>(
                        <img alt='' src={carousel.image} key={index} className='cursor-pointer' onClick={()=>workNavigateHandler(carousel)} width='400rem'/>
                    ))
                }
                {
                    carouselData.map((carousel,index)=>(
                        <img alt='' src={carousel.image} key={index} className='cursor-pointer' onClick={()=>workNavigateHandler(carousel)} width='400rem'/>
                    ))
                }
            </div>
        </div>

    </div>
  )
}

export default Carousel
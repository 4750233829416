import React from 'react'
import image1 from '../../Assets/work/FootBalance/image1.png'
import ellipse from '../../Assets/work/truffles/Ellipse 41.png'
import ellipseHeader from '../../Assets/work/truffles/Ellipse 36.png'
import Footer from '../../Components/Footer'
import WorkPageConnect from '../../Components/WorkPageConnect'
import Carousel from '../../Components/Carousel'
import firstSlide1 from '../../Assets/work/FootBalance/firstSlide1.png'
import firstSlide2 from '../../Assets/work/FootBalance/firstSlide2.png'
import firstSlide3 from '../../Assets/work/FootBalance/firstSlide3.png'
import firstSlide4 from '../../Assets/work/FootBalance/firstSlide4.png'
import firstSlide5 from '../../Assets/work/FootBalance/firstSlide5.png'
import firstSlide6 from '../../Assets/work/FootBalance/firstSlide6.png'
import secondSlide1 from '../../Assets/work/FootBalance/secondSlide1.png'
import secondSlide2 from '../../Assets/work/FootBalance/secondSlide2.png'
import secondSlide3 from '../../Assets/work/FootBalance/secondSlide3.png'
import secondSlide4 from '../../Assets/work/FootBalance/secondSlide4.png'
import secondSlide5 from '../../Assets/work/FootBalance/secondSlide5.png'
import secondSlide6 from '../../Assets/work/FootBalance/secondSlide6.png'
import thirdSlide1 from '../../Assets/work/FootBalance/thirdSlide1.png'
import thirdSlide2 from '../../Assets/work/FootBalance/thirdSlide2.png'
import thirdSlide3 from '../../Assets/work/FootBalance/thirdSlide3.png'
import thirdSlide4 from '../../Assets/work/FootBalance/thirdSlide4.png'
import thirdSlide5 from '../../Assets/work/FootBalance/thirdSlide5.png'
import fourthSlide1 from '../../Assets/work/FootBalance/fourthSlide1.png'
import fourthSlide2 from '../../Assets/work/FootBalance/fourthSlide2.png'
import fourthSlide3 from '../../Assets/work/FootBalance/fourthSlide3.png'
import fourthSlide4 from '../../Assets/work/FootBalance/fourthSlide4.png'
import fourthSlide5 from '../../Assets/work/FootBalance/fourthSlide5.png'
import fourthSlide6 from '../../Assets/work/FootBalance/fourthSlide6.png'

const FootBalance = () => {

    // const images =[image1,[image2L,image2R],image3,image4,[image5L,image5R]];
    const firstSlide = [firstSlide1,firstSlide2,firstSlide3,firstSlide4,firstSlide5,firstSlide6];
    const secondSlide = [secondSlide1,secondSlide2,secondSlide3,secondSlide4,secondSlide5,secondSlide6];
    const thirdSlide = [thirdSlide1,thirdSlide2,thirdSlide3,thirdSlide4,thirdSlide5];
    const fourthSlide = [fourthSlide1,fourthSlide2,fourthSlide3,fourthSlide4,fourthSlide5,fourthSlide6];

  return (
    <div>

        {/* Written text area */}
        <div className='projectSectionContent relative mt-[22rem]'>
            <div className='projectWidth w-[80%] mx-auto flex justify-between mb-[3rem]'>

                <div className='w-[50%] projectNameParent'>
                    <h1 className='proejectName flex gap-3 clashDisplay text-[4rem] text-[#fff] mb-[1rem]'>
                    FootBalance India
                    </h1>
                    <p className='projectsPara clashDisplay text-[#fff] w-[32rem]'>Foot Balance India is foot orthotic brand dealing in the field of podiatry which deals with all kinds of problems with feet. Experience custom comfort and a personalised fit, together with all the support you need for your every adventure.</p>
                </div>

                <div className='projectRightDiv text-[#fff] w-[40%] flex flex-col gap-6 mb-[5rem] z-50'>
                    <div>
                        <p className='projectPageSubHeading'>Client:</p>
                        <h4 className='projectPageSideTexts'>FootBalance India</h4>
                    </div>

                    <div className='w-[100%]'>
                        <p className='projectPageSubHeading'>Services:</p>
                        <div className='flex gap-4 flex-wrap projectPageSideTexts'>
                            <p className='projectPageSideText'>Smm</p>
                        </div>
                    </div>

                    <div>
                        <p className='projectPageSubHeading'>Country:</p>
                        <h4 className='projectPageSideTexts'>India</h4>
                    </div>
                </div>

            </div>
            <div className='headerEllipse absolute -top-[22rem] right-0 w-[40rem]'>
                <img alt='' src={ellipseHeader}/>
            </div>
            <div className='projectArrow absolute bottom-0 left-[40%]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 69 69" fill="none">
                    <path d="M56.3122 8.86506L65.9179 8.86506L65.9179 60.0553C65.9179 62.7078 63.7675 64.8581 61.1151 64.8582L9.92477 64.8582L9.92477 55.2525L49.1735 55.2525L3.6914 9.77034L10.4837 2.97798L56.3122 48.8068L56.3122 8.86506Z" fill="#FFCC2E"/>
                </svg>
            </div>
        </div>
        
        {/* Images Section */}
        {/* <div>
            {
                images.map((image,index)=>{
                    <div key={index}>
                        {Array.isArray(image) ? (
                            <div className='flex'>
                                {
                                    image.map((subImage, subIndex) => (
                                    <img key={subIndex} src={subImage} alt={`Image ${subIndex}`} />
                                    ))
                                }
                            </div>
                        ): (
                            <img src={image} alt={`Image ${index}`} />
                        )

                        }
                    </div>
                })
            }
        </div> */}
        <div className='relative'>
            
            <div className='projectImagesDiv w-[80%] mx-auto flex flex-col justify-center gap-[2rem] z-50'>
            
                <div className='mx-auto object w-[100%] z-50'>
                    <img alt='' src={image1} className='workImages object-cover' width='100%'/>
                </div>

            </div>

            <div className='flex flex-col gap-[2rem] mt-[2rem]'>

                <div className='w-[100%] flex gap-[2rem] footBalanceSlider1'>
                    {
                        firstSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                    {
                        firstSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                </div>

                <div className='w-[100%] flex gap-[2rem] footBalanceSlider2'>
                    {
                        secondSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                    {
                        secondSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                </div>

                <div className='w-[100%] flex gap-[2rem] footBalanceSlider1'>
                    {
                        thirdSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                    {
                        thirdSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                </div>

                <div className='w-[100%] flex gap-[2rem] footBalanceSlider2'>
                    {
                        fourthSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                    {
                        fourthSlide.map((img,index)=>(
                            <img alt='' key={index} src={img} width='400rem'/>
                        ))
                    }
                </div>
            
            </div>

            <div className='imageSectionEllipse absolute top-0 w-[28rem]'>
                <img alt='' src={ellipse}/>
            </div>
            
        </div>

        {/* Carousel */}
        <Carousel/>

        {/* Have Project */}
        <WorkPageConnect/>

        {/* Footer */}
        <Footer/>

    </div>
  )
}

export default FootBalance
import React, { useState } from 'react'
import AdoredByClients from '../Components/AdoredByClients'
import Footer from '../Components/Footer'
import Connect from '../Components/Connect'
import project1 from '../Assets/projects/Project1.png'
import project2 from '../Assets/projects/Project2.png'
import project3 from '../Assets/projects/Project3.png'
import project4 from '../Assets/projects/Project4.png'
import project5 from '../Assets/projects/Project5.png'
import project6 from '../Assets/projects/Project6.png'
import project7 from '../Assets/projects/Project7.png'
import project8 from '../Assets/projects/Project8.png'
import project9 from '../Assets/projects/Project9.png'
import project10 from '../Assets/projects/Project10.png'
import project11 from '../Assets/projects/Project11.png'
import project12 from '../Assets/projects/Project12.png'
import project13 from '../Assets/projects/Project13.png'
import project14 from '../Assets/projects/Project14.png'
import project15 from '../Assets/projects/Project15.png'
import project16 from '../Assets/projects/Project16.png'
import project17 from '../Assets/projects/Project17.png'
import project18 from '../Assets/projects/Project18.png'
import project19 from '../Assets/projects/Project19.png'
import project20 from '../Assets/projects/Project20.png'
import project21 from '../Assets/projects/Project21.png'
import { Fade } from 'react-reveal'
import { NavLink } from 'react-router-dom'

const WorkRoute = () => {

  // const Navigate = useNavigate();

  const projData =[
    {
      image: project1,
      tags: ['logo','branding'],
      route: '/work/truffles'
    },
    {
      image: project2,
      tags: ['website'],
      route: '/work/foodadda'
    },
    {
      image: project3,
      tags: ['logo','smm','website'],
      route: '/work/oji'
    },
    {
      image: project4,
      tags: ['logo','website'],
      route: '/work/envy'
    },
    {
      image: project5,
      tags: [],
      route: '/work/intellexo'
    },
    {
      image: project6,
      tags: ['logo','branding'],
      route: '/work/botego'
    },
    {
      image: project7,
      tags: ['logo','branding'],
      route: '/work/modernite'
    },
    {
      image: project8,
      tags: ['logo'],
      route: '/work/report_prime'
    },
    {
      image: project9,
      tags: [],
      route: '/work/zero_asset_group'
    },
    {
      image: project10,
      tags: ['website','ui/ux'],
      route: '/work/alpha_capital'
    },
    {
      image: project11,
      tags: ['logo','apps','ui/ux'],
      route: '/work/flivery'
    },
    {
      image: project12,
      tags: ['logo'],
      route: '/work/aanthrobot_ai'
    },
    {
      image: project13,
      tags: ['smm'],
      route: '/work/footbalance'
    },
    {
      image: project14,
      tags: ['logo'],
      route: '/work/fantazy'
    },
    {
      image: project15,
      tags: ['logo'],
      route: '/work/lynxcx'
    },
    {
      image: project16,
      tags: ['logo','branding']
    },
    {
      image: project17,
      tags: ['animated videos']
    },
    {
      image: project18,
      tags: ['web app design']
    },
    {
      image: project19,
      tags: ['logo','branding']
    },
    {
      image: project20,
      tags: ['logo']
    },
    {
      image: project21,
      tags: ['logo']
    }
  ];

  const [filterData, setFilterData] = useState(projData);
  const [clickedValue,setClickedValue] = useState('all');

  const setData = (category) =>{
    setClickedValue(category);
    if (category==='all'){
      setFilterData(projData)
    }
    else{
    const newData = projData.filter((data)=> data.tags.includes(`${category}`));
    setFilterData(newData);
    console.log(newData);
    }
  };
    
  // function imgClickHandler(projData){
  //   Navigate(projData.route);
  // }
 
  const workNavigateHandler = () =>{
    window.scrollTo({top:0,left:0});
  }


 
  

  // const [allClick, setAllClick] = useState(true);
  // const [logoClick, setLogoClick] = useState(false);
  // const [brandingClick, setBrandingClick] = useState(false);
  // const [websiteClick, setWebsiteClick] = useState(false);
  // const [webAppClick, setWebAppClick] = useState(false);
  // const [uiClick, setUiClick] = useState(false);
  // const [smmClick, setSmmClick] = useState(false);
  // const [vidClick, setVidClick] = useState(false);

  // function allClickHandler(){
  //   setAllClick(true);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function logoClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(true);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function brandingClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(true);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function websiteClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(true);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function webAppClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(true);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function uiClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(true);
  //   setSmmClick(false);
  //   setVidClick(false);
  // }
  // function smmClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(true);
  //   setVidClick(false);
  // }
  // function vidClickHandler(){
  //   setAllClick(false);
  //   setLogoClick(false);
  //   setBrandingClick(false);
  //   setWebsiteClick(false);
  //   setWebAppClick(false);
  //   setUiClick(false);
  //   setSmmClick(false);
  //   setVidClick(true);
  // }

  return (
    
    <div>

      {/* Header */}
      <div className='w-[80%] mx-auto flex justify-between items-end workHeader'>

        {/* Heading */}
        <Fade top distance='20%' duration='1500'>
          <h1 className='clashDisplay text-[#fff] text-[3.5rem] workRouteheading'>
            Work that is <br/><span className='skConcretica'>visible !!</span> 
          </h1>
        </Fade>

        {/* Buttons */}
        
        <div className='workNav dmSans font-bold'>
          <Fade top distance='50%' duration='1500'>

          <Fade left distance='50%' duration='1500'>
            <button 
              onClick={()=>setData('all')}
              className='workNavItems'
              style={{
                border: clickedValue==='all' ? "1px solid #FFCC2E" : ""
              }}>
              all
            </button>
          </Fade>

          <Fade left distance='100%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('logo')}
              style={{
                  border: clickedValue==='logo' ? "1px solid #FFCC2E" : ""
                }}>logo
            </button>
            </Fade>

            <Fade left distance='150%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('branding')}
              style={{
                  border: clickedValue==='branding' ? "1px solid #FFCC2E" : ""
                }}>branding
            </button>
            </Fade>

            <Fade left distance='200%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('website')}
              style={{
                  border: clickedValue==='website' ? "1px solid #FFCC2E" : ""
                }}>website
            </button>
            </Fade>

            <Fade left distance='250%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('web app design')}
              style={{
                  border: clickedValue==='web app design' ? "1px solid #FFCC2E" : ""
                }}>web app design
            </button>
            </Fade>

            <Fade left distance='600%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('ui/ux')}
              style={{
                  border: clickedValue==='ui/ux' ? "1px solid #FFCC2E" : ""
                }}>ui/ux
            </button>
            </Fade>

            <Fade left distance='550%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('smm')}
              style={{
                  border: clickedValue==='smm' ? "1px solid #FFCC2E" : ""
                }}>smm
            </button>
            </Fade>

            <Fade left distance='400%' duration='1500'>
            <button className='workNavItems'
              onClick={()=>setData('animated videos')}
              style={{
                  border: clickedValue==='animated videos' ? "1px solid #FFCC2E" : ""
                }}>animated videos
            </button>
            </Fade>

          </Fade>
          
        </div>

      </div>


      {/* Main Area */}
      <div>
        
        <div className='workGrid'>
          {
            filterData.map((proj,index)=>(
              
              <Fade top distance='20%' duration='1500'>
              <div key={index} className='relative projDiv'>
                
                <NavLink to={proj.route} onClick={workNavigateHandler}>
                  <img alt='' src={proj.image} loading='lazy'/>
                </NavLink>
                
                <div className='flex text-[#fff] uppercase gap-4 absolute top-4 right-4'>
                  {
                    proj.tags.map((tag,index)=>(
                      <p key={index}  className={
                        proj.image===project7 || proj.image===project16 ?  "workTagsBlack text-[#2D2D2D]" : "workTags"
                      }>{tag}</p>
                    ))
                  }
                </div>
              </div>
              </Fade>
            ))
            
          }
          </div>
        
      </div>

      <AdoredByClients/>
      <Connect/>
      <Footer/>

    </div>
  )
}

export default WorkRoute
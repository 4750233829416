import React from 'react'
import image1 from '../../Assets/work/Flivery/image1.png'
import image2L from '../../Assets/work/Flivery/image2L.png'
import image2R from '../../Assets/work/Flivery/image2R.png'
import image3 from '../../Assets/work/Flivery/image3.png'
import image4 from '../../Assets/work/Flivery/image4.png'
import image5L from '../../Assets/work/Flivery/image5L.png'
import image5R from '../../Assets/work/Flivery/image5R.png'
import image6 from '../../Assets/work/Flivery/image6.png'
import image7 from '../../Assets/work/Flivery/image7.png'
import ellipse from '../../Assets/work/truffles/Ellipse 41.png'
import ellipseHeader from '../../Assets/work/truffles/Ellipse 36.png'
import Footer from '../../Components/Footer'
import WorkPageConnect from '../../Components/WorkPageConnect'
import Carousel from '../../Components/Carousel'

const Flivery = () => {

    // const slidingImages = [slideImg1,slideImg2,slideImg3,slideImg4,slideImg5,slideImg6,slideImg7];

  return (
    <div>

        {/* Written text area */}
        <div className='relative mt-[22rem] projectSectionContent z-50'>
            <div className='projectWidth w-[80%] mx-auto flex justify-between mb-[3rem]'>

                <div className='w-[78%]'>
                    <h1 className='proejectName flex gap-3 clashDisplay text-[4rem] text-[#fff] mb-[1rem] w-[110%]'>
                    Flivery
                    </h1>
                    <p className='clashDisplay projectsPara text-[#fff] w-[32rem]'>Flivery is your go-to solution for hassle-free grocery shopping. With Flivery user-friendly app, we've simplified the process of ordering groceries, making it a breeze to find and purchase fresh produce, pantry staples, and more.</p>
                </div>

                <div className='projectRightDiv text-[#fff] w-[40%] flex flex-col gap-6 mb-[5rem] z-50'>
                    <div>
                        <p className='projectPageSubHeading'>Client:</p>
                        <h4 className='projectPageSideTexts'>Flivery</h4>
                    </div>

                    <div className='w-[100%]'>
                        <p className='projectPageSubHeading'>Services:</p>
                        <div className='flex gap-4 flex-wrap projectPageSideTexts'>
                            <p className='projectPageSideText'>Logo</p>
                            <p className='projectPageSideText'>App</p>
                            <p className='projectPageSideText'>ui/ux</p>
                        </div>
                    </div>

                    <div>
                        <p className='projectPageSubHeading'>Country:</p>
                        <h4 className='projectPageSideTexts'>India</h4>
                    </div>
                </div>

            </div>
            <div className='headerEllipse absolute -top-[22rem] right-0 w-[40rem] z-50'>
                <img alt='' src={ellipseHeader} className='z-50'/>
            </div>
            <div className='projectArrow absolute bottom-0 left-[40%]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 69 69" fill="none">
                    <path d="M56.3122 8.86506L65.9179 8.86506L65.9179 60.0553C65.9179 62.7078 63.7675 64.8581 61.1151 64.8582L9.92477 64.8582L9.92477 55.2525L49.1735 55.2525L3.6914 9.77034L10.4837 2.97798L56.3122 48.8068L56.3122 8.86506Z" fill="#FFCC2E"/>
                </svg>
            </div>
        </div>
        
        {/* Images Section */}
        {/* <div>
            {
                images.map((image,index)=>{
                    <div key={index}>
                        {Array.isArray(image) ? (
                            <div className='flex'>
                                {
                                    image.map((subImage, subIndex) => (
                                    <img key={subIndex} src={subImage} alt={`Image ${subIndex}`} />
                                    ))
                                }
                            </div>
                        ): (
                            <img src={image} alt={`Image ${index}`} />
                        )

                        }
                    </div>
                })
            }
        </div> */}
        <div className='relative'>
            <div className='projectImagesDiv w-[80%] mx-auto flex flex-col justify-center gap-[2rem] z-50'>
                
                <div className='mx-auto object w-[100%] z-50'>
                    <img alt='' src={image1} className='workImages object-cover' width='100%'/>
                </div>
                <div className='projectGridGap grid grid-cols-2 gap-[2rem]'>
                    <img alt='' src={image2L} className='workImages'/>
                    <img alt='' src={image2R} className='workImages'/>
                </div>
                <div>
                    <img alt='' src={image3} className='workImages'/>
                </div>
                <div>
                    <img alt='' src={image4} className='workImages'/>
                </div>
                <div className='projectGridGap grid grid-cols-2 gap-[2rem]'>
                    <img alt='' src={image5L} className='workImages'/>
                    <img alt='' src={image5R} className='workImages'/>
                </div>
            
            </div>

            <div>
                <img alt='' src={image6} className='workImages'/>
            </div>

            <div className='projectImagesDiv w-[80%] mx-auto flex flex-col justify-center gap-[2rem] z-50'>
                <div>
                    <img alt='' src={image7} className='workImages'/>
                </div>
            </div>

            <div className='imageSectionEllipse absolute top-0 w-[28rem]'>
                <img alt='' src={ellipse}/>
            </div>
        </div>

        {/* Carousel */}
        <Carousel/>

        {/* Have Project */}
        <WorkPageConnect/>

        {/* Footer */}
        <Footer/>

    </div>
  )
}

export default Flivery
import React from 'react'
import FooterBase from './FooterBase'
import FooterConnectSection from './FooterConnectSection'
import FooterSecondPart from './FooterSecondPart'

const Footer = () => {
  return (
    <div className='flex flex-col footerMain gap-0 ' id='contactus'>

        {/* First Part */}
        <div>
            <div className='w-[80%] footerFirst mx-auto p-[5rem] flex flex-col gap-8'>

                {/* Logo */}
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="65" viewBox="0 0 70 74" fill="none">
                        <path d="M68.6232 14.5395L63.4844 17.4912L58.2649 20.5034L45.2917 13.0284L40.1631 10.0767L34.8527 7.01421L40.0722 4.00207L45.2917 1L55.7409 7.01421L55.6501 7.06458L68.6232 14.5395Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M68.7049 29.429L63.4854 32.4411L58.2659 35.4432V20.5034L63.4854 17.4912L68.6242 14.5396L68.7049 29.429Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.4391 20.5034V47.4716L6.21954 44.4696L1 41.4675L1.08077 14.5396L6.21954 17.4912L11.4391 20.5034Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M24.4123 13.0288L11.4391 20.5037L6.21958 17.4916L1.08081 14.5399L14.0539 7.06494L19.1826 10.0166L19.1927 10.0267L24.4123 13.0288Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M34.8442 19.042L21.8711 26.517L16.7424 23.5552L16.6515 23.5048L11.432 20.5028L24.4051 13.0278L29.6247 16.04L29.7054 16.0903L34.8442 19.042Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M58.2649 47.4814V59.5099L53.0453 62.5119L47.8258 65.5241L34.8527 72.999V60.9605L47.8258 53.4957L53.0453 50.4835L58.2649 47.4814Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M34.8518 60.9615V72.9999L21.8787 65.525V53.4966L34.8518 60.9615Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M34.8518 48.9317V60.9702L21.8787 53.4952V41.4668L34.8518 48.9317Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.8711 26.5176V65.5143L16.6515 62.5021L11.432 59.5001V20.5034L16.6515 23.5055L16.7424 23.5559L21.8711 26.5176Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M58.257 20.5034L53.0374 23.5155L52.9567 23.5558L47.8179 26.5176L34.8447 19.0426L29.706 16.0909L29.6252 16.0406L24.4057 13.0284L19.1861 10.0264L19.176 10.0163L14.0474 7.06458L24.4057 1L29.6151 4.00207L29.6252 4.01214L34.8447 7.01421L40.1552 10.0666L45.2939 13.0284L58.257 20.5034Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M58.2582 20.5034V35.4432L53.0386 38.4554L47.8191 41.4675V26.5176L52.9579 23.5559L53.0386 23.5156L58.2582 20.5034Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M68.7039 29.4307V41.4692L63.4844 44.4712L58.2649 47.4834L53.0453 50.4854L47.8258 53.4976L34.8527 60.9725V48.934L47.8258 41.4692L53.0453 38.457L58.2649 35.4449L63.4844 32.4428L68.7039 29.4307Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                {/* Connect section */}
                <FooterConnectSection/>
            </div>
        </div>

        {/* Second Part */}
        <FooterSecondPart/>
        

        {/* Third Part */}
        <div className='bg-[#0D0C0D] footerThird px-[5rem] py-[1.5rem]'>
            <FooterBase/>
        </div>

    </div>
  )
}

export default Footer
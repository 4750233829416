import React, { useState } from 'react'
import logo from '../Assets/logo.png';
import MobileNavBar from './MobileNavBar';
import { Link, scroller } from 'react-scroll';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import truffles from '../Assets/NavbarBg/truffles.png'
import botego from '../Assets/NavbarBg/botego.png'
import envy from '../Assets/NavbarBg/envy.png'
import foodadda from '../Assets/NavbarBg/foodadda.png'
import intellexo from '../Assets/NavbarBg/intellexo.png'
import modernite from '../Assets/NavbarBg/modernite.png'
import oji from '../Assets/NavbarBg/oji.png'
import report_prime from '../Assets/NavbarBg/report-prime.png'
import zero_asset_group from '../Assets/NavbarBg/zero-asset-group.png'
import alpha_capital from '../Assets/NavbarBg/alpha-capital.png'
import flivery from '../Assets/NavbarBg/flivery.png'
import aanthrobot_ai from '../Assets/NavbarBg/anthrobot-ai.png'
import footbalance from '../Assets/NavbarBg/footbalance.png'
import fantazy from '../Assets/NavbarBg/fantazy.png'
import lynxcx from '../Assets/NavbarBg/lynxcx.png'

const Navbar = () => {

  const navLocation = window.location.pathname.split('/');
  // console.log(navLocation[1]);

  const navLocationName = navLocation[1];

  const [open, setOpen] = useState(false);
  const [acitve, setActive] = useState(navLocationName);



  const navigate = useNavigate();
  const location = useLocation();

  const closeMobileMenu = () => setOpen(false);

  const handleServicesClick = (route) => {
    activeNavItem(route);
    console.log(location);
    if (location.pathname.includes('work') || location.pathname.includes('contactus')) {
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(route, {
          smooth: true,
          duration: 500,
          offset: 0,
        });
      }, 0);
    }
  };

  const navBg = location.pathname.split('/');
  navBg.shift();

  const getBackgroundImage = () => {
    let imageName = null;
    if (navBg.length === 2) {
      imageName = navBg[1];
      if (imageName === 'truffles') {
        return truffles;
      }
      else if (imageName === 'foodadda') {
        return foodadda;
      }
      else if (imageName === 'oji') {
        return oji;
      }
      else if (imageName === 'envy') {
        return envy;
      }
      else if (imageName === 'botego') {
        return botego;
      }
      else if (imageName === 'intellexo') {
        return intellexo;
      }
      else if (imageName === 'report_prime') {
        return report_prime;
      }
      else if (imageName === 'zero_asset_group') {
        return zero_asset_group;
      }
      else if (imageName === 'modernite') {
        return modernite;
      }
      else if (imageName === 'alpha_capital') {
        return alpha_capital;
      }
      else if (imageName === 'flivery') {
        return flivery;
      }
      else if (imageName === 'aanthrobot_ai') {
        return aanthrobot_ai;
      }
      else if (imageName === 'footbalance') {
        return footbalance;
      }
      else if (imageName === 'fantazy') {
        return fantazy;
      }
      else if (imageName === 'lynxcx') {
        return lynxcx;
      }
    }
    return imageName;
  }

  const activeNavItem = (route) => {
    setActive(route);
  }

  return (
    // `url(./Assets/${getBackgroundImage()}.png)`
    //  style={{backgroundImage:getBackgroundImage() ? "url(./Assets/truffles.png)": "none"}}
    <div className='relative navbarMain'>
      <div className='relative flex navMain justify-between w-[80%] mx-auto font-bold uppercase text-[16px] text-white items-center py-6 mb-[5rem]'>

        {/* Logo */}
        {<div className='navLogo z-[300]'>
          <NavLink to='/' onClick={() => activeNavItem(null)}>
            <img src={logo} width='75%' alt='' />
          </NavLink>
        </div>}

        {/* Services-Work-About Us */}
        <div className='ml-[14rem] navButtons flex text-[0.8rem] gap-[50px] dmSans z-[300]'>

          <Link to='services' className='cursor-pointer z-[300]' smooth={true} duration={500} offset={-100} onClick={() => handleServicesClick('services')}
            style={{
              color: acitve === 'services' ? "#FFCC2E" : "",
              borderBottom: acitve === 'services' ? "1.5px solid #FFCC2E" : "",
              paddingBottom: acitve === 'services' ? "2px" : "",
              transition: acitve === 'services' ? "all ease 200ms" : ""
            }}>
            <h3>Services</h3>
          </Link>
          <NavLink to='/work' className='cursor-pointer z-[300]' onClick={() => activeNavItem('work')}
            style={{
              color: acitve === 'work' ? "#FFCC2E" : "",
              borderBottom: acitve === 'work' ? "1.5px solid #FFCC2E" : "",
              paddingBottom: acitve === 'work' ? "2px" : "",
              transition: acitve === 'work' ? "all ease 200ms" : ""
            }}>
            <h3>Work</h3>
          </NavLink>
          <Link to='aboutus' className='cursor-pointer z-[300]' smooth={true} duration={500} offset={0} onClick={() => handleServicesClick('aboutus')}
            style={{
              color: acitve === 'aboutus' ? "#FFCC2E" : "",
              borderBottom: acitve === 'aboutus' ? "1.5px solid #FFCC2E" : "",
              paddingBottom: acitve === 'aboutus' ? "2px" : "",
              transition: acitve === 'aboutus' ? "all ease 200ms" : ""
            }}>
            <h3>About Us</h3>
          </Link>

        </div>

        {/* Contact Us */}
        <div className='text-[0.8rem] navButtons dmSans z-[300]'>
          <NavLink to='/contactus' className='cursor-pointer z-[300]'

            style={{
              color: acitve === 'contactus' ? "#FFCC2E" : "",
              borderBottom: acitve === 'contactus' ? "1.5px solid #FFCC2E" : "",
              paddingBottom: acitve === 'contactus' ? "2px" : "",
              transition: acitve === 'contactus' ? "all ease 200ms" : ""
            }}
            onClick={() => activeNavItem('contactus')}
          >
            <p>Contact Us</p>
          </NavLink>
        </div>



        {/* Burger Button */}
        <div className='burgerButtonDiv absolute right-[5%] top-0 z-[600]'>
          <button onClick={() => setOpen(!open)}>
            {open ?
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className='mt-[2.5rem] crossButton z-50' width="23" height="20" viewBox="0 0 14 15" fill="none">
                  <path d="M1 1.35156L12.6673 13.0188" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1 12.5L12.5 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <button>
                  {/* <Lottie
                        animationData={hamburgerAnimation}
                        loop={false}
                        color='#fff'
                      /> */}
                </button>
              </div>
              :
              <svg className='burgerButton  z-50' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
              </svg>
            }
          </button>
        </div>

        {/* <div className={open ? "slideNavActive" : "slideNav"}>
            {open && <MobileNavBar isMobile={true} closeMobileMenu={closeMobileMenu}/>}
            </div> */}

        <div className="slideNav"
          style={{ transform: open ? "translateX(0)" : "translateX(100vw)", transition: "all 700ms ease-in" }}>
          <MobileNavBar isMobile={true} closeMobileMenu={closeMobileMenu} />
        </div>

      </div>

      {
        // eslint-disable-next-line
        open ||
        // eslint-disable-next-line
        getBackgroundImage() && (
          <div className='absolute top-0 left-0 z-[200] w-[100%] bg-[#01030C]'>
            <img src={getBackgroundImage()} alt='' className='w-[100%] object-fill z-[200]' />
          </div>
        )
      }


      {/* Background Vector */}
      <div className='absolute -right-[1rem] navVector -top-[3rem]'>
        <svg xmlns="http://www.w3.org/2000/svg" width="500" height="150" viewBox="0 0 655 184" fill="none">
          <path opacity="0.22" d="M924.781 -1566H67.5591C31.3509 -1566 1.99719 -1539.14 1.99719 -1506V-1233V-47.5C1.99719 -14.365 31.3509 12.5 67.5591 12.5H106.896C143.105 12.5 172.458 39.365 172.458 72.5V123C172.458 156.135 201.812 183 238.02 183H752" stroke="white" stroke-opacity="0.8" stroke-width="2" stroke-dasharray="4 3" />
        </svg>
      </div>
    </div>
  )
}

export default Navbar
import React from 'react'
import cube from '../Assets/connect cube.png'
import Lottie from 'lottie-react';
import staticBg from '../Assets/static.json'

const workPageConnect = () => {
  return (
    <div className='w-screen relative h-[300px] mt-[3rem] workConnect connect'>
        <div className='dotBG'>
            <div className=' w-auto flex flex-col justify-evenly h-[17.5rem]'>
                <div className='text-[#fff] text-[2.2rem] text-center leading-[2.5rem] connectHead'>
                    <p className='clashDisplay '>Have a project in Mind? <br/>
                        <span className='skConcretica'>You are at the right place!</span>
                    </p>
                </div>
                <button className='text-[#fff] connectButton relative self-center text-[1.7rem] overflow-hidden skConcretica border-[1px] border-solid border-[#fff] rounded-xl px-14 py-2'>
                    <Lottie animationData={staticBg} className=' opacity-10 absolute w-[17.5rem] left-0 bottom-0' />
                    Start Now
                </button>
            </div>
            <div className='absolute -right-[7.5rem] -bottom-[5rem] w-[40%] connectCube'>
                <img alt='' src={cube} width='80%'/>
            </div>
        </div>
    </div>
  )
}

export default workPageConnect
import React from 'react'

const Testimonial = ({testimonial}) => {
  return (
    <div className='text-[#fff] testimonial rounded-lg flex flex-col justify-between bg-[#0C0C0C] border-[1px] border-solid border-[#383838] h-[16rem] w-[22rem] p-[1rem] px-[1.5rem]'>
        <p className='dmSans test-[1rem]'>{testimonial.review}</p>

        <div className='flex items-center align-baseline gap-[2rem]'>
            <img alt='' src={testimonial.profile} width='15%'/>
            <p className='skConcretica text-[1.4rem]'>{testimonial.name}</p>
        </div>
    </div>
  )
}

export default Testimonial
import React from 'react' 
import ellipse from '../Assets/Ellipse 36.png'
import dotted from '../Assets/dotted bg.png'
import brandImg from '../Assets/service1.png'
import digitalImg from '../Assets/service2.png'
import userExpImg from '../Assets/service3.png'
import vidProdImg from '../Assets/service4.png'
import brandIcon from '../Assets/brandIcon.png'
import marketingIcon from '../Assets/digital-marketing icon.svg'
import uiIcon from '../Assets/ui icon.svg'
import vidIcon from '../Assets/vid-production icon.svg'
import Card from './Card'
import { Fade } from 'react-reveal'


function Services() {

    const cardData = [
        {
            title: 'Brand & Identity',
            about: 'Design that breathes life into your brand.',
            image: brandImg,
            icon: brandIcon
        },
        {
            title: 'Digital Marketing',
            about: 'Burst your marketing Bubble.',
            image: digitalImg,
            icon: marketingIcon
        },
        {
            title: 'User Experience',
            about: 'Creating intuitive user journeys.',
            image: userExpImg,
            icon: uiIcon
        },
        {
            title: 'Video production',
            about: 'Say it with visuals! - Create a lasting impact with our high-quality. ',
            image: vidProdImg,
            icon: vidIcon
        },
    ];
    // console.log(cardData)

  return (
    <div className='service-main mx-auto h-full mt-[0rem] relative' id='services'>
        <div className='text-[#fff] flex items-center justify-between relative serviceHeading'>
            
            <Fade top distance='20%' duration='1000'>
                <p className='text-[3.3rem] font-normal leading-[4rem] clashDisplay service-mainHeading w-[40rem] z-10 -my-[2rem]' id='services'>Brand presence is imperative, <span className='skConcretica'>and we'll be the catalyst.</span>  </p>
                <p className='mb-20 text-[0.9rem] leading-[1.5rem] serviceSubHeading dmSans'>Our services are a panacea to all your digital needs.</p>
            </Fade>

            <div className='absolute -left-[10rem] -top-[15rem] '>
                <img src={ellipse} alt='' width='75%'/>
            </div>   

        </div>

        <div className='absolute -left-[10rem] bottom-0'>
            <img src={dotted} alt='' width='70%'/>
        </div>

        {/* <div className='flex gap-10 mt-[8rem] pb-[8rem]'>
            <Card props={cardData[0]}/>
            <Card props={cardData[1]}/>
            <Card props={cardData[2]}/>
            <Card props={cardData[3]}/>
        </div> */}

        <div className='flex flex-row justify-center gap-8 mt-[8rem] pb-[8rem] mx-[1rem] serviceCards'>
            {
                cardData.map((card,index)=>{
                    return <Card title={card.title} about={card.about} image={card.image} icon={card.icon} key={index}/>
                })
            }
        </div>
        
        
        
        
    </div>
  )
}

export default Services
import React from 'react';
import AdoredByClients from '../Components/AdoredByClients';
import Clients from '../Components/Clients';
import Connect from '../Components/Connect';
import HeroSection from '../Components/HeroSection';
import Services from '../Components/Services';
import Slides from '../Components/Slides';
import VisibleWork from '../Components/VisibleWork';
import Work from '../Components/Work';
import Footer from '../Components/Footer';

const HomePage = () => {
  return (
    <div>
        <HeroSection/>
        <Services/>
        <Slides/>
        <Work/>
        <Clients/>
        <VisibleWork/>
        <AdoredByClients/>
        <Connect/>
        <Footer/>
    </div>
  )
}

export default HomePage
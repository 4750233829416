import React, { useState, useEffect } from 'react'
import FooterSecondPart from '../Components/FooterSecondPart'
import FooterBase from '../Components/FooterBase'
import Cube from '../Assets/contactusCube.png'
import { Fade } from 'react-reveal'
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

const ContactUs = () => {

    const helpIn = ['logo', 'branding', 'website', 'app', 'motion graphics', 'digital marketing', 'photography', 'videography']
    const spends = ['50k-1Lac', '1 Lac - 3 Lac', '4 Lac - 10 Lac', '10 Lac - 15Lac'];
    const experices = ['Internship', 'Full-Time', 'Part-Time'];
    const locations = ['on-site', 'hybrid', 'remote'];

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        // eslint-disable-next-line
    }, []);

    const [gotProject, setGotProject] = useState(true);

    function buttonChnageHandler() {
        setGotProject(!gotProject);
    }

    const [checkedItems, setCheckedItems] = useState([]);
    const [checkSpend, setCheckSpend] = useState(null);
    const [checkExperience, setCheckExperience] = useState(null);
    const [checkLocation, setCheckLocation] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneno: '',
        more: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCheck = (item) => {
        const currentIndex = checkedItems.indexOf(item);
        const newCheckedItems = [...checkedItems];
        if (currentIndex === -1) {
            newCheckedItems.push(item);
        } else {
            newCheckedItems.splice(currentIndex, 1);
        }
        setCheckedItems(newCheckedItems);
    };

    const handleSpend = (item) => {
        setCheckSpend(item === checkSpend ? null : item);
    };

    const handleLocation = (item) => {
        setCheckLocation(item === checkLocation ? null : item);
    };

    const handleExperience = (item) => {
        setCheckExperience(item === checkExperience ? null : item);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(firebase.functions())
        const sendEmail = firebase.functions().httpsCallable('sendEmail');
        // console.log(sendEmail)
        try {
            let newFormData = { ...formData };
            if (gotProject) {
                newFormData['help_in'] = checkedItems;
                newFormData['willing_to_spend'] = checkSpend;
            } else {
                newFormData['experience_level'] = checkExperience;
                newFormData['work_preference'] = checkLocation;
            }
            // console.log("newFormData", newFormData);
            const result = await sendEmail(newFormData);
            console.log("result", result.data);
            setFormData({ name: '', email: '', phoneno: '', more: '' });
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };


    return (
        <div>


            <form className='w-[80%] mx-auto contactForm' onSubmit={handleSubmit}>

                <Fade top distance='20%' duration='1500'>
                    <h1 className='contactHeading clashDisplay text-[3.5rem] text-[#fff] w-[48rem]'>Questions, dilemmas, or a friendly 'hello' – <span className='skConcretica'>we're here for it all</span></h1>
                </Fade>

                <div className='flex gap-6 mt-[2rem] contactChangeButtons'>
                    <Fade left distance='20%' duration='1500'>
                        <button onClick={buttonChnageHandler}
                            style={{
                                backgroundColor: gotProject ? "#6343FF" : "",
                                border: gotProject ? "0px" : "1px solid #ffffff1a",
                                // borderColor: gotProject? "" : "#ffffff1a",
                            }}
                            className='dmSans contactChangeButton uppercase px-4 py-3 bg-[] rounded-[0.8rem] text-[#fff]'>got a project?
                        </button>
                    </Fade>

                    <Fade left distance='40%' duration='1500'>
                        <button onClick={buttonChnageHandler}
                            style={{
                                backgroundColor: gotProject ? "" : "#6343FF",
                                border: gotProject ? "1px solid #ffffff1a" : "0px",
                                // borderColor: gotProject? "#ffffff1a" : "",
                            }}
                            className='dmSans contactChangeButton uppercase px-4 py-3 border-[1px] border-[] rounded-[0.8rem] text-[#fff]'>got talent?
                        </button>
                    </Fade>
                </div>

                {/* Got a project form */}
                {
                    gotProject &&

                    <div className='changingForm'>

                        <div className='flex flex-col gap-[1.5rem] my-[4rem]'>
                            <Fade top distance='20%' duration='1500'>
                                <h2 className='formHeading clashDisplay text-[2rem] text-[#fff]'>I’m Looking for help in....</h2>
                            </Fade>
                            <div className='flex gap-3 checkMain'>
                                {
                                    helpIn.map((help, index) => (
                                        <div key={index} className={`check ${checkedItems.includes(help) ? 'checked' : ''}`} onClick={() => handleCheck(help)}>
                                            {/* <input type='checkbox' value={help} name={help}/> 
                                        <label htmlFor={help}>{help}</label> */}
                                            {help}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='flex flex-col gap-[1.5rem] my-[4rem]'>
                            <Fade top distance='20%' duration='1500'>
                                <h2 className='formHeading clashDisplay text-[2rem] text-[#fff]'>I’m wiling to spend....</h2>
                            </Fade>
                            <div className='flex gap-3 radioMain'>
                                {
                                    spends.map((spend, index) => (
                                        <div key={index} onClick={() => handleSpend(spend)} className={`check ${spend === checkSpend ? 'checked' : ''}`}>
                                            {/* <input type='radio' name='spend' />
                                        <label htmlFor='spend' className='text-[rgb(56,56,56)] dmSans uppercase border-[1px] p-2 border-[#ffffff1a] rounded-[0.75rem]'>{spend}</label> */}
                                            {spend}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='inputFieldMain'>
                            <div className='flex gap-[3rem] subInputField'>
                                <input type='text' onChange={handleChange} value={formData.name} required name='name' placeholder='My Name' className='inputField' />
                                <input type='email' onChange={handleChange} value={formData.email} required name='email' placeholder='My Email' className='inputField' />
                            </div>
                            <div className='flex gap-[3rem] subInputField'>
                                <input type='number' onChange={handleChange} value={formData.phoneno} name='phoneno' placeholder='My Phone No.' className='inputField' />
                                <input type='text' onChange={handleChange} value={formData.more} name='more' placeholder='Tell Us more' className='inputField' />
                            </div>
                        </div>

                        <button type='submit' className='dmSans uppercase px-4 py-3 bg-[#6343FF] rounded-[0.8rem] text-[#fff] mt-[4rem]'>send a request</button>

                    </div>
                }

                {/* Got Talent form */}
                {
                    !gotProject &&

                    <div className='changingForm'>

                        <div className='flex flex-col gap-[1.5rem] my-[4rem]'>
                            <Fade top distance='20%' duration='1500'>
                                <h2 className='formHeading clashDisplay text-[2rem] text-[#fff]'>Interested in working with us?  Experience level</h2>
                            </Fade>
                            <div className='flex gap-3 checkMain'>
                                {
                                    experices.map((experience, index) => (
                                        <div key={index} onClick={() => handleExperience(experience)} className={`check ${experience === checkExperience ? 'checked' : ''}`}>

                                            {/* <input type='checkbox' value={experice} name={experice}/> 
                                        <label htmlFor={experice}>{experice}</label> */}
                                            {experience}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='flex flex-col gap-[1.5rem] my-[4rem]'>
                            <Fade top distance='20%' duration='1500'>
                                <h2 className='formHeading clashDisplay text-[2rem] text-[#fff]'>On-site/remote</h2>
                            </Fade>
                            <div className='flex gap-3 radioMain'>
                                {
                                    locations.map((location, index) => (
                                        <div key={index} onClick={() => handleLocation(location)} className={`check ${location === checkLocation ? 'checked' : ''}`}>
                                            {/* <input type='radio' name='spend' />
                                        <label htmlFor='spend' className='text-[rgb(56,56,56)] dmSans uppercase border-[1px] p-2 border-[#ffffff1a] rounded-[0.75rem]'>{location}</label> */}
                                            {location}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='inputFieldMain'>
                            <div className='flex gap-[3rem] subInputField'>
                                <input type='text' onChange={handleChange} value={formData.name} required name='name' placeholder='My Name' className='inputField' />
                                <input type='email' onChange={handleChange} value={formData.email} required name='email' placeholder='My Email' className='inputField' />
                            </div>
                            <div className='flex gap-[3rem] subInputField'>
                                <input type='number' onChange={handleChange} value={formData.phoneno} name='phoneno' pattern='[0-9]{8,10}' placeholder='My Phone No.' className='inputField' />
                                <input type='text' onChange={handleChange} value={formData.more} name='more' placeholder='Apply for' className='inputField' />
                            </div>
                        </div>

                        <button type='submit' className='dmSans uppercase px-4 py-3 bg-[#6343FF] rounded-[0.8rem] text-[#fff] mt-[4rem]'>send a request</button>

                    </div>
                }

            </form>

            <footer className='-mt-[4rem] contactFooter'>
                <div className='w-[80%] mx-auto px-[5rem] flex justify-between items-center -mb-[3   rem] contactFooter-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="65" viewBox="0 0 70 74" fill="none">
                        <path d="M68.6232 14.5395L63.4844 17.4912L58.2649 20.5034L45.2917 13.0284L40.1631 10.0767L34.8527 7.01421L40.0722 4.00207L45.2917 1L55.7409 7.01421L55.6501 7.06458L68.6232 14.5395Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M68.7049 29.429L63.4854 32.4411L58.2659 35.4432V20.5034L63.4854 17.4912L68.6242 14.5396L68.7049 29.429Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.4391 20.5034V47.4716L6.21954 44.4696L1 41.4675L1.08077 14.5396L6.21954 17.4912L11.4391 20.5034Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M24.4123 13.0288L11.4391 20.5037L6.21958 17.4916L1.08081 14.5399L14.0539 7.06494L19.1826 10.0166L19.1927 10.0267L24.4123 13.0288Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M34.8442 19.042L21.8711 26.517L16.7424 23.5552L16.6515 23.5048L11.432 20.5028L24.4051 13.0278L29.6247 16.04L29.7054 16.0903L34.8442 19.042Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M58.2649 47.4814V59.5099L53.0453 62.5119L47.8258 65.5241L34.8527 72.999V60.9605L47.8258 53.4957L53.0453 50.4835L58.2649 47.4814Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M34.8518 60.9615V72.9999L21.8787 65.525V53.4966L34.8518 60.9615Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M34.8518 48.9317V60.9702L21.8787 53.4952V41.4668L34.8518 48.9317Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M21.8711 26.5176V65.5143L16.6515 62.5021L11.432 59.5001V20.5034L16.6515 23.5055L16.7424 23.5559L21.8711 26.5176Z" stroke="#6343FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M58.257 20.5034L53.0374 23.5155L52.9567 23.5558L47.8179 26.5176L34.8447 19.0426L29.706 16.0909L29.6252 16.0406L24.4057 13.0284L19.1861 10.0264L19.176 10.0163L14.0474 7.06458L24.4057 1L29.6151 4.00207L29.6252 4.01214L34.8447 7.01421L40.1552 10.0666L45.2939 13.0284L58.257 20.5034Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M58.2582 20.5034V35.4432L53.0386 38.4554L47.8191 41.4675V26.5176L52.9579 23.5559L53.0386 23.5156L58.2582 20.5034Z" stroke="#FFCC2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M68.7039 29.4307V41.4692L63.4844 44.4712L58.2649 47.4834L53.0453 50.4854L47.8258 53.4976L34.8527 60.9725V48.934L47.8258 41.4692L53.0453 38.457L58.2649 35.4449L63.4844 32.4428L68.7039 29.4307Z" stroke="#FF3E99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <img alt='' src={Cube} width='40%' className='-mr-[9rem] contactCube' />
                </div>
                <FooterSecondPart />
                <div className='bg-[#0D0C0D] footerThird px-[5rem] py-[1.5rem]'>
                    <FooterBase />
                </div>
            </footer>

        </div>
    )
}

export default ContactUs
import React from 'react'
import { NavLink } from 'react-router-dom'

const FooterConnectSection = () => {
    const workNavigateHandler = () =>{
        window.scrollTo({top:0,left:0});
    }
  return (
    <div>
        <div className='text-[#fff] flex flex-wrap gap-[20%] z-[300] footerGap'>

            <div className='flex flex-col gap-2 z-[300]'>

                <p className='text-[0.9rem] dmSans'>Got a project?</p>

                <NavLink to='/contactus' onClick={workNavigateHandler}>
                    <div className='flex gap-6  items-baseline z-[300]'>
                        <h3 className='skConcretica text-[2rem] z-[300]'>Lets Talk</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34" fill="none">
                            <path d="M3.23465 5.24848L3.23465 1.1251e-06L31.3611 3.584e-06C32.8185 3.71141e-06 34 1.17502 34 2.62424L34 30.5941L28.7222 30.5941L28.7222 9.14902L3.73205 34L-3.49025e-06 30.2888L25.1806 5.24848L3.23465 5.24848Z" fill="white"/>
                        </svg>
                    </div>
                </NavLink>

            </div>

            <div className='flex flex-col gap-2 z-[300]'>
            
                <p className='text-[0.9rem] flex-grow  dmSans z-[300]'>Get Talent?</p>

                <NavLink to='/contactus' onClick={workNavigateHandler}>
                    <div className='flex gap-6  items-baseline z-[300]'>
                        <h3 className='skConcretica text-[2rem] z-[300]'>Join Trebled</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34" fill="none">
                            <path d="M3.23465 5.24848L3.23465 1.1251e-06L31.3611 3.584e-06C32.8185 3.71141e-06 34 1.17502 34 2.62424L34 30.5941L28.7222 30.5941L28.7222 9.14902L3.73205 34L-3.49025e-06 30.2888L25.1806 5.24848L3.23465 5.24848Z" fill="white"/>
                        </svg>
                    </div>
                </NavLink>

            </div>
            {/* <div className=' opacity-0'>
                <p className='text-[1rem] dmSans'>zz</p>
                <h3 className='skConcretica text-[2rem]'>zz</h3>
            </div> */}
        </div>
    </div>
  )
}

export default FooterConnectSection